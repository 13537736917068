.Wizard-btn-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 620px;
    overflow: auto;
}

.Wizard-btn-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 0 20px;
    max-width: 200px;
    min-width: 200px;
}

.Wizard-btn-box:hover {
    cursor: pointer;
}

.Wizard-btn-big {
    width: 150px;
    height: 150px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
    margin: 20px 20px 10px 20px;
    box-shadow: 0px 0px 20px 5px #0000000f;
}

.Wizard-btn-big:hover {
    /* width: 120px;
    height: 120px; */
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 5px #00000086;
}

.Close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
}

.Close-btn:hover {
    color: white;
}