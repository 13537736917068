.mainContainer {
  width: 100%;
  margin-top: 30px;
}

.sigContainer {
  width: 100%;
  max-width: 1200px;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  margin: auto;
  width: 1024px;
  min-width: 550px;
  height: 550px;
  border: 1px solid;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 150px;
  width: 500px;
  background-color: #f8f8f8;
  margin: 20px;
  padding: 10px;
}
