.label-box {
    background: white;
    max-width: 375px;
    min-width: 375px;
    padding: 2%;
    margin: 0;
    height: 185px;
    max-height: 185px;
    color: black;
}

.label-row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
  text-align: center;
}
.label-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.label-col-item {
  width: 100%;
}

.customer-name-label{
  font-size: 12px;
  margin-top: -4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
