.ImageContainer {
    position: relative;
  }
  
  .ImageDiv {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    opacity: 1;
    display: block;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }
  
  .ImageMiddle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999;
  }
  
  .ImageContainer:hover .ImageDiv {
    opacity: 0.2;
  }
  
  .ImageContainer:hover .ImageMiddle {
    opacity: 1;
    cursor: pointer;
  }
  